import { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';

import { addCart } from '../../../redux/application/actions';
import classes from './Addtocart.module.css';

import { ShoppingbagIcon, PlusIcon, RefreshIcon } from "../../icons";

const Addtocart = ({ product }) => {
    const dispatch = useDispatch();
    const { cart } = useSelector(state => state.app);

    const { id, price } = product;
    const { qty } = cart[id] || { qty: 0 };

    const [quantity, setQuantity] = useState(qty);
    const [btnStyle, setBtnStyle] = useState('secondary');

    const disabled = (quantity === '' ||  quantity === qty);

    useEffect(() => {
        setQuantity(qty);
    }, [qty])

    const onChangeQuantity = ({ target: { value } }) => {
        setQuantity(value);
    };

    const onSubmit = (event) => {
        event.preventDefault();

        if (quantity === '' || quantity === qty) {
            return;
        }

        let cartArray = { ...cart };

        if (id in cart && parseInt(quantity) === 0) {
            delete cartArray[id]
        } else if (parseInt(quantity) > 0) {
            cartArray[id] = { product, 'qty': quantity, 'total_price': Number(quantity * price).toFixed(2).replace('.', ',') };
        }

        dispatch(addCart(cartArray));

        setBtnStyle('success');
        setTimeout(() => {
            setBtnStyle('secondary');
        }, 2000);
    }

    return (
        <form className={classes.root} onSubmit={onSubmit}>
            <input type="number" name="qty" onChange={onChangeQuantity} value={quantity} className={classes.input} min="0" max="9999" />
            <button className={classes.add_to_cart_button + ' button ' + btnStyle + ' ' + (disabled ? classes.disabled : '')} type="submit" aria-label="Toevoegen" disabled={disabled}>

                {qty < 1
                    ?
                    <>
                        <ShoppingbagIcon size="16" />
                        <PlusIcon size="9" />
                    </>
                    :
                    <RefreshIcon size="20" />
                }

            </button>
        </form>
    );
}

export default Addtocart;