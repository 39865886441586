import { Fragment, useMemo, useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import qs from 'qs';

import { Button } from '../form';

import { Row, Col } from '../grid';

import classes from './ProductGrid.module.css';

const usePages = (products, range, per_page) => {

    return useMemo(() => {
        const { start, stop } = range;

        const start_index = (start-1)*per_page;
        const stop_index = (stop)*per_page;

        const sliced_products = [...products].slice(start_index, stop_index);

        let page = start;
        let i = 0;
        let p = 0;

        return sliced_products.reduce((pages, product) => {

            if (!pages[p]) {
                pages[p] = {
                    number: page,
                    products: [product]
                };
            } else {
                pages[p].products.push(product);
            }

            if (i === (per_page)-1) {
                page++;
                p++;
                i = 0;
            } else {
                i++;
            }

            return pages;

        }, []);
    

    }, [products, range, per_page]);

}

const ProductGrid = ({ products = [], renderItem, renderLoadMore, per_page=20 }) => {

    const navigate = useNavigate();
    const { pathname, search } = useLocation();

    const [ page_range, setPageRange ] = useState({
        start: 1,
        stop: qs.parse(search.replace('?', '')).page || 1
    });

    const pages = usePages(products, page_range, per_page);

    /*useEffect(() => {
        setPageRange({ start: 1, stop: 1 });
    }, [products])*/

    useEffect(() => {

        const { page = 1 } = qs.parse(search.replace('?', ''));

        setPageRange((range) => {
            return {
                ...range,
                stop: page
            }
        });

    }, [search]);

    const showed = useMemo(() => {
        return pages.reduce((count, page) => {
            count += page.products.length;
            return count;
        }, 0)
    }, [pages]);

    const nextPage = () => {
        const range = { ...page_range };

        const params = qs.parse(search.replace('?', ''));
        params.page = Number(range.stop) + 1;
        
        navigate(pathname + '?' + qs.stringify(params));
    }

    return (
        <Row>
  
            {
                pages.map(({ number, products }, index) => {
                    return (
                        <Fragment key={'p'+index}>

                            {
                                products.map((product, index) => {
                                    return (
                                        <Col key={index} md={6}>
                                            {renderItem(product, index)}
                                        </Col>
                                    );
                                })
                            }
                                        
                        </Fragment>
                    )
                })
            }

            <Col md={12} className={classes.product_shown}>
                U heeft { showed } van de { products.length } producten gezien.
            </Col>

            {
                (showed < products.length)
                &&
                <Col md={12} className={classes.load_more}>
                    <Button variant="transparent" type='button' onClick={ nextPage }>Laad meer</Button>
                </Col>
            }

        </Row>
    );
}

export default ProductGrid;