import { Builder, BuilderBlocks } from "@builder.io/react/lite";
import { Container, Row, Col } from '../../components/grid';

const defaultBlocks = [
    {
        '@type': '@builder.io/sdk:Element',
        component: {
            name: 'Dummy'
        },
    },
];

export const EightFourGrid = (props) => {
    if (props.firstblock.length > 0 && props.firstblock[0].component.name === "Dummy") {
        props.firstblock.shift();
    }
    if (props.secondblock.length > 0 && props.secondblock[0].component.name === "Dummy") {
        props.secondblock.shift();
    }
    return (< Container xl margins g-xs={2}>
        <Row>
            <Col md={8}>
                <BuilderBlocks
                    child
                    blocks={props.firstblock}
                    parentElementId={props.builderBlock && props.builderBlock.id}
                    dataPath={`component.options.firstblock`}
                />
            </Col>
            <Col md={4}>
                <BuilderBlocks
                    child
                    blocks={props.secondblock}
                    parentElementId={props.builderBlock && props.builderBlock.id}
                    dataPath={`component.options.secondblock`}
                />
            </Col>
        </Row>
    </Container >)
};

Builder.registerComponent(EightFourGrid, {
    name: "EightFourGrid",
    static: true,
    inputs: [
        {
            name: 'firstblock',
            type: 'uiBlocks',
            hideFromUI: false,
            defaultValue: defaultBlocks
        },
        {
            name: 'secondblock',
            type: 'uiBlocks',
            hideFromUI: false,
            defaultValue: defaultBlocks
        }
    ],
});