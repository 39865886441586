import { useCallback } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { addConfig } from '../redux/application/actions';

import Kerstpakketten from '../lib/api/Kerstpakketten';

const useConfig = () => {
    const dispatch = useDispatch();

    const { delivery_deliver,  delivery_pickup,  minimum_order_quantity, usps } = useSelector(state => state.app);

    const fetchConfig = useCallback(async () => {

        try {
            const { data={} } = await Kerstpakketten.getConfig();

            const { delivery_deliver,  delivery_pickup,  minimum_order_quantity, usps=[] } = data?.attributes;

            let parsedUsps = usps.map((usp) => {
                return {
                    ...usp,
                    icon: usp?.icon?.data?.attributes?.url
                }
            })

            dispatch(addConfig({ 
                delivery_deliver,  
                delivery_pickup,  
                minimum_order_quantity, 
                usps: parsedUsps
            }));
        } catch (error) {
            console.log(error);
        }

    }, [dispatch]);

    return {
        fetchConfig,
        delivery_deliver,
        delivery_pickup,
        minimum_order_quantity,
        usps
    };
}

export default useConfig;