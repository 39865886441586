import IconWrapper from '../wrapper/IconWrapper';

const CrossIcon = ({ size = 15 , color = null, className = null }) => {
    return (
        <IconWrapper size={size} color={color} className={className}>
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.454629 0.454655C0.665598 0.24375 0.951694 0.12527 1.25 0.12527C1.54831 0.12527 1.83441 0.24375 2.04538 0.454655L8 6.40928L13.9546 0.454655C14.0584 0.347206 14.1825 0.261501 14.3198 0.202541C14.4571 0.143581 14.6047 0.112547 14.7541 0.111249C14.9034 0.10995 15.0516 0.138415 15.1898 0.194981C15.3281 0.251547 15.4537 0.335082 15.5593 0.440711C15.665 0.54634 15.7485 0.671948 15.8051 0.810207C15.8616 0.948465 15.8901 1.0966 15.8888 1.24598C15.8875 1.39536 15.8565 1.54298 15.7975 1.68024C15.7385 1.81749 15.6528 1.94163 15.5454 2.04541L9.59075 8.00003L15.5454 13.9547C15.7503 14.1668 15.8637 14.451 15.8611 14.746C15.8586 15.041 15.7403 15.3231 15.5317 15.5317C15.3231 15.7403 15.0409 15.8586 14.746 15.8612C14.451 15.8637 14.1668 15.7503 13.9546 15.5454L8 9.59078L2.04538 15.5454C1.8332 15.7503 1.54902 15.8637 1.25405 15.8612C0.959081 15.8586 0.676917 15.7403 0.468333 15.5317C0.259749 15.3231 0.141434 15.041 0.138871 14.746C0.136307 14.451 0.249701 14.1668 0.454629 13.9547L6.40925 8.00003L0.454629 2.04541C0.243724 1.83444 0.125244 1.54834 0.125244 1.25003C0.125244 0.951721 0.243724 0.665624 0.454629 0.454655Z" fill="white"/>
            </svg>
        </IconWrapper>
    );
}

export default CrossIcon;