import { Builder } from "@builder.io/react/lite";
import { Container } from '../../components/grid';
import { Button } from '../../components/form';

import ProductSliderModule from '../../modules/sliders/ProductSlider';
import useCategory from '../../hooks/useCategory';

import classes from './ProductSlider.module.css';

export const LabelProductSlider = ({ ShopButton, label }) => {
    const { category } = useCategory('alle-kerstpakketten');

    if (category === null) { return null; }

    let labelProducts = [];
    if (category?.products) {
        labelProducts = category.products.filter(({ labels=[] }) => { 
            const labelIndex = labels.findIndex((l) => {
                return l.label === label
            });
            return (labelIndex !== -1)
        });
    }

    return (
        <Container xl g-xs={0}>
            {
                <>
                    <ProductSliderModule products={labelProducts} />
                    {
                        ShopButton === 'enabled' &&
                        <Button to="/alle-kerstpakketten" variant="transparent" className={classes.category_button} >
                            {
                                <span>Bekijk alle kerstpakketten</span>
                            }
                        </Button>
                    }
                </>
            }
        </Container>
    );
};

Builder.registerComponent(LabelProductSlider, {
    name: "LAbel Product Slider",
    inputs: [
        {
            name: 'label',
            type: 'string',
            required: true
        },
        {
            type: 'string',
            name: 'ShopButton',
            required: true,
            enum: [
                {
                    label: 'Disabled',
                    value: 'disabled',
                },
                {
                    label: 'Enabled',
                    value: 'enabled',
                }
            ],
            defaultValue: 'disabled',
        }
    ]
});