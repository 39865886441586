import Slider from '../../components/slider/Slider';
import CategoryTile from '../../components/category/tile/CategoryTile';

import useCategories from '../../hooks/useCategories';

const CategorySlider = () => {

    const { categories } = useCategories();

    const renderItem = (item) => {
        return <CategoryTile item={item} />;
    }

    if (categories === null) { return null; }

    return (
        <Slider
            items={categories}
            renderItem={renderItem}
        />
    );
}

export default CategorySlider;