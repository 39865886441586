import { Link } from 'react-router-dom';

import { Builder } from "@builder.io/react/lite";
import { Image } from "@builder.io/react/";

import { Container, Row, Col } from '../../components/grid';
import { Button } from '../../components/form';

import classes from './HomeBanner.module.css';

export const HomeBanner = props => {
    const { url1, image1, url2, image2, url3, image3, buttonlabel, ButtonType } = props;

    return (
        <Container xl margins g-xs={2}>
            <Row>
                <Col md={8} className={classes.banner_container}>
                    <div className={classes.imageContainer}>
                        <Image lazy aspectRatio={0.5102040816326531} image={image1} />
                        {
                            buttonlabel &&
                            <Button variant={ButtonType} to={url1} className={classes.homebanner_button}>
                                {
                                    <span>{buttonlabel}</span>
                                }
                            </Button>
                        }
                    </div>
                    {url1 && url1.indexOf('http') === 0 ? <a href={url1} className={classes.banner_link} target="_blank" rel="noreferrer"><span className={classes.hidden}>.</span></a> : <Link to={url1} className={classes.banner_link}></Link>}
                </Col>
                <Col md={4}>
                    <Row className={classes.smallbanner_container}>
                        {[
                            { img: image2, url: url2 },
                            { img: image3, url: url3 }
                        ].map((col, index) => (
                            <Col xs={12} key={index} className={classes.col_container}>
                                <div className={classes.imageContainer}>
                                    <Image lazy aspectRatio={0.50} image={col.img} />
                                </div>

                                {col.url && col.url.indexOf('http') === 0 ? <a href={col.url} className={classes.banner_link} target="_blank" rel="noreferrer"><span className={classes.hidden}>.</span></a> : <Link to={col.url} className={classes.banner_link}></Link>}
                            </Col>
                        ))}
                    </Row>
                </Col>
            </Row>
        </Container>
    );
}


Builder.registerComponent(HomeBanner, {
    name: "Home Banner",
    inputs: [
        {
            name: 'url1',
            type: 'string',
        }, {
            name: 'image1',
            type: 'file',
            allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg'],
            required: true,
            defaultValue:
                'https://cdn.builder.io/api/v1/image/assets%2Fpwgjf0RoYWbdnJSbpBAjXNRMe9F2%2Ffb27a7c790324294af8be1c35fe30f4d',
        }, {
            name: 'url2',
            type: 'string',
        }, {
            name: 'image2',
            type: 'file',
            allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg'],
            required: true,
            defaultValue:
                'https://cdn.builder.io/api/v1/image/assets%2Fpwgjf0RoYWbdnJSbpBAjXNRMe9F2%2Ffb27a7c790324294af8be1c35fe30f4d',
        }, {
            name: 'url3',
            type: 'string',
        }, {
            name: 'image3',
            type: 'file',
            allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg'],
            required: true,
            defaultValue:
                'https://cdn.builder.io/api/v1/image/assets%2Fpwgjf0RoYWbdnJSbpBAjXNRMe9F2%2Ffb27a7c790324294af8be1c35fe30f4d',
        },
        {
            name: 'buttonlabel',
            type: 'string',
            defaultValue: 'Button label',
        },
        {
            type: 'string',
            name: 'ButtonType',
            required: true,
            enum: [
                {
                    label: 'Primary',
                    value: 'primary',
                },
                {
                    label: 'Secondary',
                    value: 'secondary',
                },
                {
                    label: 'Transparent',
                    value: 'transparent',
                }
            ],
            defaultValue: 'primary',
        }

    ],
});
