import marked from 'marked';

import Addtocart from '../../addtocart/Addtocart';

import classes from './Info.module.css';

const Info = ({ product }) => {
    return (
        <div className={classes.root}>
            <h1 className={classes.product_name}>{product.name}</h1>
            <div className={classes.sku}>{product.sku}</div>

            <div
                dangerouslySetInnerHTML={{
                    __html: marked(product.description, { headerIds: false })
                }}
            />

            <div className={classes.info_bottom}>
                <div className={classes.price}>
                    { product.price ? Number(product.price).toFixed(2).replace('.', ',') : null }
                </div>
                { product.inStock && <Addtocart product={product} /> }
            </div>
        </div>
    );
}

export default Info;