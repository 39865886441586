import IconWrapper from '../wrapper/IconWrapper';

const CheckmarkIcon = ({ size = 24, color = null, direction = 'top', className = null }) => {
    return (
        <IconWrapper size={size} color={color} direction={direction} className={className}>
            <svg width="14" height="12" viewBox="0 0 14 12" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path d="M4.59514 12L0 7.20463L2.09408 5.0193L4.59514 7.63707L11.9059 0L14 2.18533L4.59514 12Z" />
            </svg>

        </IconWrapper>
    );
}

export default CheckmarkIcon;