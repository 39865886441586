import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import { Container, Row, Col } from '../../components/grid';

import NotFound from '../../components/not_found/NotFound';
import Loading from '../../components/loading/Loading';
import Breadcrumb from '../../components/breadcrumb/Breadcrumb';

import { useState, useEffect } from 'react';

import useMediaQuery from '../../hooks/useMediaQuery';

import BuilderContent from '../../builder/BuilderContent';

import classes from './Builder.module.css';

const Builder = ({ seo = {} }) => {
    const { pathname } = useLocation();
    const { match } = useMediaQuery('(min-width: 768px)');
    const [breadcrumbLabel, setBreadcrumbLabel] = useState('');

    useEffect(() => {
        setBreadcrumbLabel(pathname.split('/')[1].charAt(0).toUpperCase() + pathname.split('/')[1].split('-').join(' ').slice(1));
        return () => {
            setBreadcrumbLabel('');
        }
    }, []);

    return (
        <>
            <Helmet>
                <title>{seo.title || ''}</title>
                <meta name="description" content={seo.description || ''} />
            </Helmet>

            {
                match
                &&
                <Container xl margins g-xs={2}>
                    <Row>
                        <Col xs={12}>
                            <Breadcrumb
                                prefix={[{ label: 'Home', path: '/' }]}
                                sufix={[{ label: breadcrumbLabel }]}
                            />
                        </Col>
                    </Row>
                </Container>
            }

            <div className={classes.root}>
                <BuilderContent
                    model="page"
                    url={pathname}
                    renderLoading={() => <Loading page />}
                    renderNotFound={() => <NotFound />}
                />
            </div>
        </>
    );
}

export default Builder;