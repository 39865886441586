import classNames from 'classnames';
import classes from './Grid.module.css';

const Row = ({ children, className }) => {

    const rowClassName = classNames(classes.row, className);

    return (
        <div className={rowClassName}>
            { children }
        </div>
    );
}

export default Row;