import { useState } from 'react';

import classNames from 'classnames';

import SearchIcon from '../../icons/icons/SearchIcon';

import classes from './Seach.module.css';

const Seach = ({ name = "search_input", type = 'search', placeholder = ' ', error = false, required = false, onChange = null, onSearch = null, ...rest_props }) => {
    const [value, setValue] = useState('');

    const inputClasses = classNames(classes.input, (error && classes.input_error));

    const onInputChange = ({ target: { value } }) => {
        setValue(value);
        if (onChange !== null) { onChange(value); }
    }

    const onSearchClicked = () => {
        if (onSearch !== null) { onSearch(value); }
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            if (onSearch !== null) { onSearch(value); }
        }
    }

    return (
        <div className={classes.field}>

            <input
                className={inputClasses}
                type={type}
                name={name}
                value={value}
                placeholder={placeholder}
                onChange={onInputChange}
                onKeyDown={handleKeyDown}
                required={required}
                {...rest_props}
            />

            <SearchIcon className={classes.icon} onClick={onSearchClicked} />

        </div>
    );

}

export default Seach;