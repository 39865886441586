import { Link } from 'react-router-dom';

import classes from './ProductTile.module.css';
import Addtocart from '../addtocart/Addtocart';
import ProductLabels from '../labels/ProductLabels';
import Thumbnail from '../../image/Thumbnail';


const ProductTile = ({ product, category = 'alle-kerstpakketten', addtocart = true }) => {
    const { price, name, image, inStock } = product;

    const path = '/' + category + '/' + product.key;

    return (
        <div className={classes.root}>
            <div className={classes.image}>
                <Link
                    to={path}
                    state={{ product: product }}
                    className={classes.link}
                >
                    <ProductLabels product={product} />
                    {
                        image
                        &&
                        <Thumbnail
                            id={image.id}
                            alt={name}
                            images={[
                                { key: 'product_list', media: '(min-width: 10px)', width: '450', height: '225' }
                            ]}
                            className={classes.image}
                        />
                    }

                </Link>
            </div>

            <div className={classes.product_info_container}>
                <Link
                    to={path}
                    state={{ product: product }}
                    className={classes.link}
                >
                    {name}
                </Link>

                <div className={classes.bottom_wrapper}>

                    <div className={classes.price}>
                        {
                            (price)
                            &&
                            <>{Number(price).toFixed(2).replace('.', ',')}</>
                        }
                    </div>
                    
                    {
                        (addtocart && inStock) && <Addtocart product={product} />
                    }
                </div>
            </div>
        </div>
    );
}

export default ProductTile;