import { Link } from 'react-router-dom';

import { Col } from '../../../../components/grid';

import Search from '../../../../components/form/search/Seach';
import MyLocation from '../../../../components/mylocation/MyLocation';
import Loading from '../../../../components/loading/Loading';

import classes from './List.module.css';

const List = ({ locations = [], onSearch, onSearchLatLon, loading = false }) => {

    return (
        <Col md={4} className={classes.list_container} order-xs={2} order-md={1}>

            <div className={classes.header}>
                <h2 className={classes.title}>ZOEK EEN KERSTSHOWROOM</h2>

                <Search
                    onSearch={onSearch}
                    placeholder="Postcode of plaats"
                />

                <MyLocation
                    onLocation={({ latitude, longitude }) => { onSearchLatLon(latitude, longitude) }}
                />

            </div>

            <div className={classes.list}>

                {
                    loading
                        ?
                        <Loading className={classes.loading} />
                        :
                        locations.map((location) => {
                            const { key, name, postalcode, street } = location ?? {};
                            
                            return (
                                <Link
                                    key={key}
                                    to={'/showrooms/' + key}
                                    state={{ location: location }}
                                >
                                    <div className={classes.item}>
                                        <h3 className={classes.name}>{name}</h3>
                                        <span className={classes.address}>{postalcode} {street} </span>
                                        <span className={classes.link}>Meer informatie</span>
                                    </div>
                                </Link>
                            )
                        })
                }

            </div>

        </Col>
    );

}

export default List;