import { Link } from 'react-router-dom';

import Image from '../../image/Image';
import Config from '../../../lib/config/Config';


import classes from './CategoryTile.module.css';
import useCategory from '../../../hooks/useCategory';

const CategoryTile = ({ item }) => {
    const { category } = useCategory(item.key);

    const getCategoryImage = (image) => {
        return image ? Config.get('backend').image_url + image.url : '/images/placeholder/placeholder.jpg';
    };

    return (
        <Link to={'/' + item.key} className={classes.tile_link}>
            <div className={classes.image}>
                <Image lazy responsive src={getCategoryImage(category.image2)} alt={category.name} width={184} height={184} ratio="category-thumb" />
            </div>
            <div className={classes.name}>
                {category.name}
            </div>
        </Link>
    );
}

export default CategoryTile;