import { useEffect, useMemo, useReducer  } from 'react';

import Kerstpakketten from '../lib/api/Kerstpakketten';


const initialState = {
    data: null,
    error: null,
    loading: false
};

const reducer = (state, action) => {
    switch (action.type) {
        case 'footer/request':
            return  {
                ...state,
                error: null,
                loading: true
            };
        case 'footer/success':
            return {
                ...state,
                data: action.payload,
                error: null,
                loading: false
            };
        case 'footer/error':
            return {
                ...state,
                error: action.payload,
                loading: false
            };
      default:
        throw new Error();
    }
}


const useFooter = () => {
    
    const [state, dispatch] = useReducer(reducer, initialState);

    
    const footer = useMemo(() => {
        if (state.data) {
            const { link_block_1, link_block_2, link_block_3 } = state.data;
            return {
                link_block_1,
                link_block_2,
                link_block_3
            };
        } else {
            return null;
        }
    }, [state.data]);

    useEffect(() => {

        let cancelRequest = false;

        const fetchData = async () => {

            dispatch({ type: 'footer/request' });

            try {
                const { data } = await Kerstpakketten.getFooter();
                if (!cancelRequest) { dispatch({ type: 'footer/success', payload: data?.attributes }); }
            } catch (error) {
                if (!cancelRequest) { dispatch({ type: 'footer/error', payload: error }); }
            }
        };

        if (footer === null) {
            fetchData();
        }

        return () => {
            cancelRequest = true;
        };
    }, [footer]);


    return {
        footer,
        error: state.error,
        loading: state.loading
    };
}

export default useFooter;