import IconWrapper from '../wrapper/IconWrapper';

const ClipboardCheck = ({ size = 15, color = null, className = null }) => {
    return (
        <IconWrapper size={size} color={color} className={className}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M16.2811 10.719C16.3509 10.7887 16.4064 10.8714 16.4442 10.9626C16.482 11.0537 16.5014 11.1514 16.5014 11.25C16.5014 11.3487 16.482 11.4463 16.4442 11.5375C16.4064 11.6286 16.3509 11.7113 16.2811 11.781L11.7811 16.281C11.7114 16.3509 11.6287 16.4063 11.5375 16.4441C11.4464 16.4819 11.3487 16.5013 11.2501 16.5013C11.1514 16.5013 11.0538 16.4819 10.9626 16.4441C10.8715 16.4063 10.7888 16.3509 10.7191 16.281L8.46909 14.031C8.39936 13.9613 8.34405 13.8785 8.30631 13.7874C8.26857 13.6963 8.24915 13.5986 8.24915 13.5C8.24915 13.4014 8.26857 13.3037 8.30631 13.2126C8.34405 13.1215 8.39936 13.0387 8.46909 12.969C8.60992 12.8282 8.80093 12.7491 9.00009 12.7491C9.09871 12.7491 9.19636 12.7685 9.28747 12.8062C9.37858 12.844 9.46136 12.8993 9.53109 12.969L11.2501 14.6895L15.2191 10.719C15.2888 10.6492 15.3715 10.5937 15.4626 10.5559C15.5538 10.5181 15.6514 10.4987 15.7501 10.4987C15.8487 10.4987 15.9464 10.5181 16.0375 10.5559C16.1287 10.5937 16.2114 10.6492 16.2811 10.719Z" fill="white" />
                <path d="M6 2.25H4.5C3.70435 2.25 2.94129 2.56607 2.37868 3.12868C1.81607 3.69129 1.5 4.45435 1.5 5.25V21C1.5 21.7956 1.81607 22.5587 2.37868 23.1213C2.94129 23.6839 3.70435 24 4.5 24H19.5C20.2956 24 21.0587 23.6839 21.6213 23.1213C22.1839 22.5587 22.5 21.7956 22.5 21V5.25C22.5 4.45435 22.1839 3.69129 21.6213 3.12868C21.0587 2.56607 20.2956 2.25 19.5 2.25H18V3.75H19.5C19.8978 3.75 20.2794 3.90804 20.5607 4.18934C20.842 4.47064 21 4.85218 21 5.25V21C21 21.3978 20.842 21.7794 20.5607 22.0607C20.2794 22.342 19.8978 22.5 19.5 22.5H4.5C4.10218 22.5 3.72064 22.342 3.43934 22.0607C3.15804 21.7794 3 21.3978 3 21V5.25C3 4.85218 3.15804 4.47064 3.43934 4.18934C3.72064 3.90804 4.10218 3.75 4.5 3.75H6V2.25Z" fill="white" />
                <path d="M14.25 1.5C14.4489 1.5 14.6397 1.57902 14.7803 1.71967C14.921 1.86032 15 2.05109 15 2.25V3.75C15 3.94891 14.921 4.13968 14.7803 4.28033C14.6397 4.42098 14.4489 4.5 14.25 4.5H9.75C9.55109 4.5 9.36032 4.42098 9.21967 4.28033C9.07902 4.13968 9 3.94891 9 3.75V2.25C9 2.05109 9.07902 1.86032 9.21967 1.71967C9.36032 1.57902 9.55109 1.5 9.75 1.5H14.25ZM9.75 0C9.15326 0 8.58097 0.237053 8.15901 0.65901C7.73705 1.08097 7.5 1.65326 7.5 2.25V3.75C7.5 4.34674 7.73705 4.91903 8.15901 5.34099C8.58097 5.76295 9.15326 6 9.75 6H14.25C14.8467 6 15.419 5.76295 15.841 5.34099C16.2629 4.91903 16.5 4.34674 16.5 3.75V2.25C16.5 1.65326 16.2629 1.08097 15.841 0.65901C15.419 0.237053 14.8467 0 14.25 0L9.75 0Z" fill="white" />
            </svg>
        </IconWrapper>
    );
}


export default ClipboardCheck;