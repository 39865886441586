import Button from '../../../form/button/Button';

import classes from './ComposeCta.module.css';

const ComposeCta = () => {
    return (
        <div className={classes.root}>
            <h2 className={classes.title}>Zelf samenstellen? Dat kan!</h2>
            <div>
                <p>
                    Stel een kerstpakket samen met een specialist bij uw vestiging. Persoonlijker kan niet!
                </p>
                <Button variant="transparent" to="/zelf-samenstellen">
                    {
                        <span>Maak een afspraak</span>
                    }
                </Button>
            </div>
        </div>
    );
}

export default ComposeCta;