import { Builder } from "@builder.io/react/lite";
import { Image } from "@builder.io/react";
import { Container, Row, Col } from '../../components/grid';
import classes from './TwoColumnBanner.module.css';
import { Link } from "react-router-dom";

export const TwoColumnBanner = props => {
    const { url1, image1, url2, image2 } = props;

    return (
        <Container xl margins g-xs={2}>
            <Row>
                {[
                    { img: image1, url: url1 },
                    { img: image2, url: url2 }
                ].map((col, index) => (
                    <Col key={index} md={6} className={classes.banner_container}>
                        <div className={classes.imageContainer}>
                            <Image lazy aspectRatio={0.4109} image={col.img} />
                        </div>
                        {col.url && <Link to={col.url} className={classes.banner_link}><span className={classes.hidden}>.</span></Link>}
                    </Col>
                ))}
            </Row>
        </Container>
    );
}


Builder.registerComponent(TwoColumnBanner, {
    name: "Two Column Banner",
    inputs: [
        {
            name: 'url1',
            type: 'string',
        }, {
            name: 'image1',
            type: 'file',
            allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg'],
            required: true,
            defaultValue:
                'https://cdn.builder.io/api/v1/image/assets%2Fpwgjf0RoYWbdnJSbpBAjXNRMe9F2%2Ffb27a7c790324294af8be1c35fe30f4d',
        }, {
            name: 'url2',
            type: 'string',
        }, {
            name: 'image2',
            type: 'file',
            allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg'],
            required: true,
            defaultValue:
                'https://cdn.builder.io/api/v1/image/assets%2Fpwgjf0RoYWbdnJSbpBAjXNRMe9F2%2Ffb27a7c790324294af8be1c35fe30f4d',
        }
    ],
});
