import { useState } from 'react';

import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

import ProductLabels from '../../labels/ProductLabels';
import Thumbnail from '../../../image/Thumbnail';

import classNames from 'classnames';
import classes from './Gallery.module.css';

const Gallery = ({ product }) => {
    const [open, setOpen] = useState(false);
    const modalClass = classNames(classes.modal, (open ? classes.open : null));
    const { name, image } = product;

    const openZoom = () => {
        setOpen(true);
    }

    const closeZoom = () => {
        setOpen(false);
    }

    return (
        <>
        
            <div className={classes.root} onClick={() => openZoom()}>
                <ProductLabels product={product} />
                {
                    image
                    &&
                    <Thumbnail
                        id={image.id}
                        alt={name}
                        images={[
                            { key: 'product_details', media: '(min-width: 10px)', width: '784', height: '392' }
                        ]}
                        className={classes.image}
                    />
                }
            </div>
    
            <div className={modalClass}>
                <div onClick={() => closeZoom()} className={classes.close}></div>
                {
                    (open && image)
                    &&
                    <TransformWrapper centerOnInit={true}>
                        {({ zoomIn, zoomOut }) => (
                            <>
                                <div className={classes.tools}>
                                    <div onClick={() => zoomIn()} variant="secondary" className={classes.plus}></div>
                                    <div onClick={() => zoomOut()} variant="secondary" className={classes.min}></div>
                                </div>
                                <TransformComponent>

                                    <Thumbnail
                                        id={image.id}
                                        alt={name}
                                        images={[
                                            { key: 'product_details_zoom', media: '(min-width: 10px)', width: '1920', height: '960' }
                                        ]}
                                        className={classes.image}
                                    />


                                </TransformComponent>
                            </>
                        )}
                    </TransformWrapper>
                }
            </div>
        </>
    );
}

export default Gallery;