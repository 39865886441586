import IconWrapper from '../wrapper/IconWrapper';

const FilterIcon = ({ size = 24, color = null, direction = 'top', className = null }) => {
    return (
        <IconWrapper size={size} color={color} direction={direction} className={className}>
            <svg width="14" height="14" viewBox="0 0 14 14" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.5 0.5C0.5 0.367392 0.552678 0.240215 0.646447 0.146447C0.740215 0.0526784 0.867392 0 1 0H13C13.1326 0 13.2598 0.0526784 13.3536 0.146447C13.4473 0.240215 13.5 0.367392 13.5 0.5V2.5C13.5 2.62331 13.4544 2.74226 13.372 2.834L9 7.692V12.5C8.99992 12.6049 8.96685 12.7071 8.90547 12.7922C8.8441 12.8772 8.75752 12.9409 8.658 12.974L5.658 13.974C5.58287 13.999 5.50288 14.0058 5.4246 13.9939C5.34632 13.982 5.272 13.9516 5.20775 13.9053C5.1435 13.859 5.09116 13.7982 5.05504 13.7277C5.01893 13.6572 5.00006 13.5792 5 13.5V7.692L0.628 2.834C0.545608 2.74226 0.500022 2.62331 0.5 2.5V0.5ZM1.5 1V2.308L5.872 7.166C5.95439 7.25774 5.99998 7.37669 6 7.5V12.806L8 12.14V7.5C8.00002 7.37669 8.04561 7.25774 8.128 7.166L12.5 2.308V1H1.5Z" />
            </svg>
        </IconWrapper>
    );
}

export default FilterIcon;