import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import { Col, Row, Container } from '../grid';

const NotFound = () => {
    return (
        <>
            <Helmet>
                <meta name="robots" content="noindex, follow"></meta>
            </Helmet>

            <Container xl margins g-xs={2}>
                <Row>
                    <Col md={8}>
                        <h1>Pagina niet gevonden!</h1>
                        <p>
                            De pagina die u zoekt is momenteel niet beschikbaar.<br />
                            Gebruik een van onderstaande links of de navigatie om verder te gaan.
                        </p>
                        <ul>
                            <li><Link to="/">Ga naar de homepage</Link></li>
                            <li><Link to="/alle-kerstpakketten">Bekijk onze kerstpakketten</Link></li>
                            <li><Link to="/showrooms">Zoek een showroom</Link></li>
                        </ul>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default NotFound;