import { useState } from 'react';
import classNames from 'classnames';

import { ChevronIcon } from '../icons';

import classes from './Collapsable.module.css';

const Collapsable = ({ children, className, renderTitle, collapsed = false, disabled = false }) => {

    const [open, setOpen] = useState(!collapsed);

    const rootClassName = classNames(classes.root, {
        [classes.open]: open
    }, className);

    const onClick = () => {
        setOpen(!open);
    }

    return (
        <div className={rootClassName}>
            {
                disabled
                ?
                <>
                    { renderTitle() }
                    { children }
                </>
                :
                <>
                    <div className={classes.title} onClick={onClick}>
                        { renderTitle() }
                        <ChevronIcon size={16} direction={open ? 'top' : 'bottom'} className={classes.icon} />
                    </div>
                    <div className={classes.body}>
                        { children }
                    </div>
                </>
            }
        </div>
    );
}

export default Collapsable;