import { useCallback, useMemo } from 'react';
import { useDispatch, useStore } from 'react-redux';

import { setCache, deleteCache, clearCache } from '../redux/cache/actions';


const useCache = () => {
    
    const dispatch = useDispatch();
    const store = useStore();
    const { cache } = store.getState();
    //const cache = useSelector(state => state.cache);

    const get = useCallback((key) => {
        return cache[key] || null;
    }, [cache]);

    const set = useCallback((key, value) => {
        dispatch(setCache({
            key,
            value
        }));
    }, [dispatch]);

    const remove = useCallback((key) => {
        dispatch(deleteCache(key));
    }, [dispatch]);

    const clear = useCallback(() => {
        dispatch(clearCache());
    }, [dispatch]);


    const methods = useMemo(() => {
        return {
            get,
            set,
            remove,
            clear
        };
    }, [get, set, remove, clear]);

    return methods;
}

export default useCache;