import classNames from 'classnames';

import { MapIcon } from '../icons';

import classes from './MyLocation.module.css';

const MyLocation = ({ onLocation=null, className=null }) => {

    const rootClasses = classNames(classes.root, className);

    const getCurrentPosition = () => {
        navigator.geolocation.getCurrentPosition((position) => {
            const { coords } = position;

            if (onLocation !== null) {
                onLocation(coords);
            }  
        });
    }

    return (
        <div className={rootClasses} onClick={getCurrentPosition}>
            <MapIcon size={15} color="#ffffff" className={classes.icon} />
            <span>Gebruik mijn huidige locatie</span>
        </div>
    );
}

export default MyLocation;