import Slider from '../../components/slider/Slider';
import ProductTile from '../../components/product/tile/ProductTile';

const ProductSlider = ({ products }) => {
    const renderItem = (item) => {
        return <ProductTile product={item} addtocart={false} />;
    }

    return (
        <Slider
            items={products}
            renderItem={renderItem}
            itemsToShow={3}
        />
    );
}

export default ProductSlider;