
import { Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import { Container, Col, Row } from '../../grid';

import useNavigation from '../../../hooks/useNavigation';
import useConfig from '../../../hooks/useConfig';

import Config from '../../../lib/config/Config';

import classes from './TopMenu.module.css';

const TopMenu = () => {
    const pages = useNavigation('top');
    const { usps } = useConfig();

    const image_url = Config.get('backend').image_url;

    return (
        <Container xl margins g-xs={2} gy-xs={0}>
            <Row>
                <Col xs={8} className={classes.usps}>
                    {
                        usps.map(({ title, icon }, index) => {
                            return (
                                <Fragment key={'usp'+index}>
                                    <img src={image_url + icon} alt={ title } width="26" height="26" /> <span>{ title }</span>
                                </Fragment>
                            )
                        })
                    }
                </Col>
                <Col xs={4} className={classes.align_right}>
                    <nav className={classes.topmenu}>
                        {
                            pages.map(({ nav_name, url_key, type }) => {
                                if (type === 'external') {
                                    return <a key={url_key} href={url_key}>{nav_name}</a>
                                } else {
                                    return <NavLink key={url_key} to={'/' + url_key}>{nav_name}</NavLink>
                                }
                            })
                        }
                    </nav>
                </Col>
            </Row>
        </Container>
    );
}

export default TopMenu;