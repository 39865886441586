import classNames from 'classnames';

import classes from './Textarea.module.css';

const Textarea = ({ name='', type='text', value='', label='', rows=5, error=false, required=false, onChange=()=>{}, ...rest_props }) => {
    
    const inputClasses = classNames(classes.textarea, (error && classes.textarea_error));

    return (
        <div className={classes.field}>
            { label !== '' && <label htmlFor={name} className={classes.label}>{ label } { (required && <span className={classes.asterisk}>*</span>)}</label> }

            <textarea 
                name={name}
                className={inputClasses} 
                onChange={onChange}
                rows={rows}
                value={value}
                required={required}
                {...rest_props}
            />

        </div>
    );
    
}

export default Textarea;