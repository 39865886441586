import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import marked from 'marked';

import useCategory from '../../hooks/useCategory';
import useFilters from '../../hooks/useFilters';
import useMediaQuery from '../../hooks/useMediaQuery';

import { FilterIcon } from '../../components/icons';
import { Col, Row, Container } from '../../components/grid';

import CategoryImage from '../../components/category/CategoryImage';
import Breadcrumb from '../../components/breadcrumb/Breadcrumb';
import ProductFilters from '../../components/product/filters/ProductFilters';
import ProductGrid from '../../components/product/ProductGrid';
import ProductTile from '../../components/product/tile/ProductTile';
import ProductSort from '../../components/product/sort/ProductSort';
import CategoryLinks from '../../components/product/categorylinks/CategoryLinks';
import Button from '../../components/form/button/Button';
import Config from '../../lib/config/Config';

import classes from './ProductList.module.css';

const ProductList = () => {
    const { pathname } = useLocation();
    const { match } = useMediaQuery('(min-width: 768px)');

    const category_key = pathname.substring(1);

    const { category } = useCategory(category_key);

    const { products, filters, active_filters, sort } = useFilters(category);

    const drawer = useState(false);
    const [open, setOpen] = drawer;

    const renderItem = (product) => {
        return <ProductTile product={product} category={category_key} />;
    }

    if (category === null) { return null; }

    const config = Config.get('productlist');

    return (
        <>
            <Helmet>
                <title>{category.seo_title || ''}</title>
                <meta name="description" content={category.seo_description || ''} />
            </Helmet>

            <Container xl margins g-xs={2}>
                <Row>
                    <Col xs={12}>
                        <Breadcrumb
                            prefix={[{ label: 'Home', path: '/' }]}
                            //paths={[{ label: 'Kerstpakketten', path: '/kerstpakketten' }]}
                            sufix={[{ label: category.name }]}
                        />
                    </Col>
                </Row>
            </Container>

            <Container xl margins g-xs={2}>
                <Row>
                    <Col xs={12}>
                        <CategoryImage category={category} />
                    </Col>
                </Row>
                <Row>
                    <Col md={2}>
                        {
                            match
                            &&
                            <CategoryLinks
                                categories={category.categories}
                            />
                        }

                        <ProductFilters
                            filters={filters}
                            activeFilters={active_filters}
                            drawer={drawer}
                        />

                    </Col>
                    <Col md={10}>
                        <Row>
                            <Col xs={12}>
                                <div className={classes.text}>
                                    <h1>{category.name}</h1>
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: marked(category.description, { headerIds: false })
                                        }}
                                    />
                                </div>
                            </Col>
                            <Col xs={12} className={classes.toolbar}>

                                <Button type="submit" variant="transparent" onClick={() => setOpen(true)} className={classes.filter_button}>
                                    <span className={classes.filter_button_text}>
                                        <span>
                                            Filter
                                        </span>
                                        <FilterIcon size="14" />
                                    </span>
                                </Button>

                                <ProductSort
                                    sort={sort}
                                />

                            </Col>
                        </Row>

                        <ProductGrid
                            products={products}
                            renderItem={renderItem}
                            per_page={config.pagination.products_per_page}
                        />

                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default ProductList;