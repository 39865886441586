import { Container, Row } from '../../components/grid';
import { Helmet } from 'react-helmet-async';

import ContactForm from '../../builder/contactform/ContactForm';
import BuilderContent from '../../builder/BuilderContent';
import Loading from '../../components/loading/Loading';

const Contact = () => {
    return (
        <>
            <Helmet>
                <title>Contactformulier</title>
                <meta name="description" content="Stel hier uw vraag aan HANOS kerst- en relatiegeschenken over het kerstpakkettenassortiment, hanoskerstpakketten.nl en de service en mogelijkheden. Dan nemen we zo snel mogelijk contact met u op." />
            </Helmet>

            <Container xl margins g-xs={2}>
                <Row>
                    <BuilderContent
                        model="contact"
                        url="/contact"
                        renderLoading={() => <Loading />}
                    />
                </Row>
                <Row>
                    <ContactForm />
                </Row>
            </Container>
        </>
    );

}

export default Contact;