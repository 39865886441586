import React, { Fragment, Suspense } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import qs from 'qs';

import useMediaQuery from '../../../hooks/useMediaQuery';

//import Drawer from '../../drawer/Drawer';
import Button from '../../form/button/Button';

import Filter from './filter/Filter';

import classes from './ProductFilters.module.css';
import { PlusIcon } from '../../icons';

const Drawer = React.lazy(() => import('../../drawer/Drawer'));

const withProps = (Component, props) => (defaultProps) => {
    return <Suspense fallback={<></>}><Component {...defaultProps} {...props} /></Suspense>;
}


const ProductFilters = ({ filters, activeFilters, drawer }) => {

    const navigate = useNavigate();
    const location = useLocation();
    const { match } = useMediaQuery('(min-width: 768px)');

    //const [filterState, setFilterState] = useState(activeFilters || {});

    const [open, setOpen] = drawer;


    //const Wrapper = match ? <Fragment /> : <Drawer open={open} />;
    const Wrapper = match ? Fragment : withProps(Drawer, { open: open, setOpen: setOpen });


    const onChange = (key, value) => {

        const changed_filters = { ...activeFilters };

        if (changed_filters[key]) {

            const index = changed_filters[key].indexOf(value);

            if (index === -1) {
                changed_filters[key].push(value);
            } else if (index > -1) {
                changed_filters[key].splice(index, 1);
            }

        } else {
            changed_filters[key] = [value];
        }

        const querystring = qs.stringify({ filter: changed_filters });
        navigate(location.pathname + '?' + querystring);
    };

    const closeFilters = () => {
        setOpen(false);
    }

    return (
        <Wrapper>
            {
                !match
                &&
                <div className={classes.filter_top_container}>
                    <div className={classes.filter_close} onClick={() => closeFilters()}>
                        <PlusIcon size="15" />
                    </div>
                    <span>
                        Filters
                    </span>
                </div>
            }
            <div className={classes.root}>
                <div>
                    {/*
                        Object.entries(activeFilters).map(([key, values]) => {
                            return <div>{key} : {values}</div>
                        })
                    */}
                </div>
                <div>
                    {
                        filters && filters.map((filter) => {
                            return (
                                <Filter
                                    key={filter.key}
                                    id={filter.key}
                                    name={filter.name}
                                    active={activeFilters[filter.key] || null}
                                    options={filter.options}
                                    onChange={onChange}
                                />
                            );
                        })
                    }
                </div>
            </div>
            {
                !match
                &&
                <div className={classes.show_products_container}>
                    <div className={classes.fade} style={{ background: 'linear-gradient(rgba(255, 255, 255, 0))' }} />
                    <Button type="submit" variant="primary" className={classes.show_products_button} onClick={() => closeFilters()}>
                        Toon producten
                    </Button>
                </div>
            }
        </Wrapper>
    );
}

export default ProductFilters;