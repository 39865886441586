import BuilderContent from '../../builder/BuilderContent';
import Loading from '../../components/loading/Loading';

const Home = () => {
    return (
        <BuilderContent
            model="page"
            url="/"
            renderLoading={() => <Loading page />}
        />
    );
}

export default Home;