import { Builder } from '@builder.io/react/lite';

import CategorySliderModule from '../../modules/sliders/CategorySlider';
import { Container } from '../../components/grid';

export const CategorySlider = () => (
    <Container xl g-xs={0}>
        <CategorySliderModule />
    </Container>
);

Builder.registerComponent(CategorySlider, {
    name: "Category Slider"
});