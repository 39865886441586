import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import qs from 'qs';

const useQueryString = () => {

    const search = useLocation().search.substring(1);
    
    const params = useMemo(() => {
        return qs.parse(search);
    }, [search]);
    
    return params;
}

export default useQueryString;