
import { Helmet } from 'react-helmet-async';
import { useParams, useLocation as useLocationRouter } from 'react-router-dom';

import marked from 'marked';

import { Col, Row, Container } from '../../../components/grid';
import { HeadSetIcon, EnvelopeIcon } from '../../../components/icons';

import useLocation from '../../../hooks/useLocation';

import Thumbnail from '../../../components/image/Thumbnail';
import Breadcrumb from '../../../components/breadcrumb/Breadcrumb';

import Timeslist from './timeslist/Timeslist';
import Appointment from './appointment/Appointment';

import classes from './LocationDetails.module.css';

const LocationDetails = () => {

    const { state } = useLocationRouter();
    const { location_key } = useParams();
    const { location } = useLocation(location_key, (state && state.location ? state.location : null));

    const { id, name, street, number, postalcode, city, phone, email, image, description, regularHours, specialHours } = location || {};
 
    return (
        <>
            <Helmet>
                <title>{ name }</title>
                <meta name="description" content="" />
            </Helmet>

            <Container xl margins g-xs={2}>

                <Row>

                    <Col xs={12}>
                        <Breadcrumb
                            prefix={[{ label: 'Home', path: '/' }]}
                            paths={[{ label: 'Showrooms', path: '/showrooms'  }]}
                            sufix={[{ label: name }]}
                        />
                    </Col>
                    
                    <Col xs={12}>
                        <h1>{ name }</h1>
                    </Col>

                </Row>

                <Row>
                    <Col md={8}>

                        {
                            image?.data
                            &&
                            <Thumbnail 
                                id={image.data.id} 
                                alt={name} 
                                images={[
                                    { key: 'location_header_mobile', media: '(max-width: 639px)', width:'320', height:'162' },
                                    { key: 'location_header', media: '(min-width: 640px)', width:'784', height:'162' }
                                ]}
                                className={classes.image}
                            />
                        }
              
                        { 
                            description 
                            &&
                            <div
                                className={classes.description}
                                dangerouslySetInnerHTML={{
                                    __html: marked(description, { headerIds: false })
                                }}
                            />
                        }

                        <h2>Maak een afspraak</h2>
                        <Appointment location_id={id}/>

                    </Col>
                    <Col md={4} className={classes.sidebar}>
                        <h2>Adresgegevens showroom</h2>
                        <div className={classes.address}>
                            <h3>{ name }</h3>
                            { street } { number }<br />
                            { postalcode }<br />
                            { city }<br />
                            <span className={classes.iconline}><HeadSetIcon size={18} color='#57004E' className={classes.icon}/>{ phone }<br /></span>
                            <span className={classes.iconline}><EnvelopeIcon size={18} color='#57004E' className={classes.icon}/>{ email }</span>
                        </div>

                        <h2>Openingstijden</h2>
                        <div className={classes.timelist}>
                            <Timeslist 
                                hours={ regularHours } 
                                specialHours={ specialHours }
                            />
                        </div>

                    </Col>
                </Row>

            </Container>
        </>
    );

}

export default LocationDetails;