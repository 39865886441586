import { useState, useEffect } from 'react';

import { Builder, builder, BuilderComponent } from '@builder.io/react/lite';

import useCache from '../hooks/useCache';

import './builder.css';

import Config from '../lib/config/Config';

import './categoryslider/CategorySlider';
import './productslider/LabelProductSlider';
import './productslider/ProductSlider';
import './twocolumnbanner/TwoColumnBanner';
import './contactform/ContactForm';
import './heading/Heading';
import './homebanner/HomeBanner';
import './layout/EightFourGrid';
import './layout/FourEightGrid';
import './layout/FullRowGrid';
import './layout/SixSixGrid';
import './layout/Dummy';

builder.init(Config.get('builder').api_key);

Builder.register('insertMenu', {
    name: 'Layout',
    items: [
        { name: 'EightFourGrid' },
        { name: 'FourEightGrid' },
        { name: 'FullRowGrid' },
        { name: 'SixSixGrid' }
    ],
});


const BuilderContent = ({ model, url = null, renderLoading = null, renderNotFound = null }) => {
    const [notFound, setNotFound] = useState(false);
    const cache = useCache();

    const builderProps = {};

    if (url !== null) {
        builderProps.url = url;
    }

    if (cache.get(url) !== null) {
        builderProps.content = cache.get(url);
    }

    useEffect(() => {

        const fetchContent = async () => {
            try {
                const content = await builder.get(model, { url: url }).promise();

                setNotFound(!content);

                if (!Builder.isPreviewing && !Builder.isEditing && content) {
                    cache.set(url, content);
                }

            } catch (error) {
                console.log(error);
            }
        }

        fetchContent();

    }, [model, url, cache]);

    return (
        <>
            {
                notFound
                    ?
                    renderNotFound && renderNotFound()
                    :
                    <BuilderComponent model={model} {...builderProps} >
                        {
                            renderLoading && renderLoading()
                        }
                    </BuilderComponent>
            }
        </>
    );
}

export default BuilderContent;