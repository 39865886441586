import { BrowserRouter, Routes, Route } from 'react-router-dom';

//import Route from './routes/LayoutRoute';
import Layout from '../../pages/Layout';

import Home from '../../pages/home/Home';
import Locations from '../../pages/locations/Locations';
import Products from '../../pages/products/Products';
import Builder from '../../pages/builder/Builder';
import Checkout from '../../pages/checkout/Checkout';
import Success from '../../pages/checkout/success/Success';

import Contact from '../../pages/contact/Contact';

import ScrollToTop from '../../components/scroll/ScrollToTop';

import useNavigation from '../../hooks/useNavigation';

const components = {
    builder: Builder,
    products: Products,
    locations: Locations,
    category: Products
};

const Router = () => {
    const pages = useNavigation();

    if (pages === null) { return null; }

    return (
        <BrowserRouter>
            <ScrollToTop />
            <Routes>
                <Route element={<Layout />}>
                    <Route index element={<Home />} />
                    { /*<Route path="/kerstpakketten" component={Products} />*/}
                    <Route path="/contact" element={<Contact />} />
                    <Route path="/checkout" element={<Checkout />} />
                    <Route path="/success" element={<Success />} />
                    {
                        pages.filter(({ type }) => {
                            return type !== 'external';
                        }).map(({ url_key, type, seo_title, seo_description, nav_name, nav_visible }) => {
                            const Page = components[type];
                            return (
                                <Route
                                    key={url_key}
                                    path={'/' + url_key + '/*'}
                                    element={<Page
                                        seo={{
                                            title: seo_title,
                                            description: seo_description
                                        }}
                                        nav={{
                                            name: nav_name,
                                            visible: nav_visible
                                        }}
                                    />}
                                />
                            );
                        })
                    }
                    <Route path="*" element={<Builder />} />
                </Route>
            </Routes>
        </BrowserRouter>
    );
}

export default Router;