import { useEffect, useState, useRef } from 'react';

import Config from '../lib/config/Config';
import Kerstpakketten from '../lib/api/Kerstpakketten';

const useLocations = () => {
    const center = useRef(null);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [locations, setLocations] = useState([]);

    const search = async (params='') => {

        setLoading(true);

        if (params !== '') {

            const response = await fetch('https://maps.googleapis.com/maps/api/geocode/json?address='+params+',NL&key=' + Config.get('google').maps_api_key)
            const { results, status } = await response.json();

            if (status === 'OK') {
                const { geometry: { location } } = results[0];

                center.current = location;

                const locations = await Kerstpakketten.getLocationsNear({
                    lon: location.lng, 
                    lat: location.lat, 
                    maxDistance: 50000 // Meters
                });

                setLocations(locations);
            } else if (status === 'ZERO_RESULTS' || status === 'OVER_DAILY_LIMIT ' || status === 'OVER_QUERY_LIMIT ' ) {
                setLocations([]);
            } else {
                setError(status);
            }

        } else {

            center.current = null;

            try {
                const { data: locations } = await Kerstpakketten.getLocations();

                const normalizedLications = locations.reduce((locations, location) => {
                    locations.push(location.attributes);
                    return locations;
                }, []);

                setLocations(normalizedLications);
            } catch (error) {
                console.log('[useLocations] Error:', error)
                setError(error);
            }
        }

        setLoading(false);
    }

    const searchLatLon = async (lat, lon) => {
        
        setLoading(true);

        try {
            const locations = await Kerstpakketten.getLocationsNear({
                lon: lon, 
                lat: lat, 
                maxDistance: 50000 // Meters
            });

            setLocations(locations);
        } catch (error) {
            setError(error);
        }
    
        setLoading(false);
    }

    useEffect(() => {
        search();
    }, []);

    return {
        locations: locations,
        error: error,
        loading: loading,
        search: search,
        searchLatLon: searchLatLon,
        center: center.current
    };;
}

export default useLocations;