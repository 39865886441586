import Image from '../image/Image';
import Config from '../../lib/config/Config';

import classes from './CategoryImage.module.css';

const CategoryImage = ({ category }) => {

    const getCategoryImage = (image) => {
        return image ? Config.get('backend').image_url + image.url : '';
    };

    return (
        <div className={classes.root}>
            <div className={classes.left}>
                {
                    category.image1
                    &&
                    <Image lazy responsive src={getCategoryImage(category.image1)} alt={category.name} width={592} height={200} ratio="category" />
                }
            </div>
            <div className={classes.right}>
                {
                    category.image2
                    &&
                    <Image lazy responsive src={getCategoryImage(category.image2)} width={592} height={200} ratio="category" />
                }
            </div>
        </div>
    );
}

export default CategoryImage;