import React from 'react';
import ReactDOM from 'react-dom';

import { Provider as ReduxProvider } from 'react-redux';
import { HelmetProvider } from 'react-helmet-async';
import { PersistGate } from 'redux-persist/integration/react';

import App from './App';
import configureStore from './redux/store';
//import initBuilder from './builder';

import './css/main.css';

const { store, persistor } = configureStore();

ReactDOM.render(
    <React.StrictMode>
        <ReduxProvider store={store}>
            <PersistGate
                loading={null}
                persistor={persistor}
                onBeforeLift={async () => {
                    return new Promise(async (resolve) => {
                        //initBuilder();
                        resolve();
                    })
                }}
            >
                <HelmetProvider>
                    <App />
                </HelmetProvider>
            </PersistGate>
        </ReduxProvider>
    </React.StrictMode>,
    document.getElementById('app')
);
