import { Builder } from "@builder.io/react/lite";
import { Container } from '../../components/grid';
import { Button } from '../../components/form';

import ProductSliderModule from '../../modules/sliders/ProductSlider';
import useCategory from '../../hooks/useCategory';

import classes from './ProductSlider.module.css';

export const ProductSlider = ({ categorie, ShopButton }) => {
    const { category } = useCategory(categorie);

    if (category === null) { return null; }

    return (
        <Container xl g-xs={0}>
            {
                !category ?
                    <div style={{ padding: 16 }}>Selecteer een categorie om producten te tonen</div>
                    :
                    <>
                        <ProductSliderModule products={category.products} />
                        {
                            ShopButton === 'enabled' &&
                            <Button to="/alle-kerstpakketten" variant="transparent" className={classes.category_button} >
                                {
                                    <span>Bekijk alle kerstpakketten</span>
                                }
                            </Button>
                        }
                    </>
            }
        </Container>
    );
};

Builder.registerComponent(ProductSlider, {
    name: "Product Slider",
    inputs: [
        {
            name: 'categorie',
            type: 'string',
            required: true
        },
        {
            type: 'string',
            name: 'ShopButton',
            required: true,
            enum: [
                {
                    label: 'Disabled',
                    value: 'disabled',
                },
                {
                    label: 'Enabled',
                    value: 'enabled',
                }
            ],
            defaultValue: 'disabled',
        }]
});