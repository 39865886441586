
const env = (key, fallback) => {
    const env = process.env;
    return (env.hasOwnProperty('REACT_APP_' + key) ? env['REACT_APP_' + key] : fallback);
}

/* eslint import/no-anonymous-default-export: [0, {"allowAnonymousClass": true}] */
export default {

    default: {

        backend: {
            url: env('BACKEND_URL', 'http://localhost:1337/api'),
            port: null,
            image_url: env('IMAGE_URL', 'http://localhost:1337'),
        },

        builder: {
            api_key: env('BUILDER_API_KEY', '53728347bd16444b8c01d846e1fe70f8'),
        },

        ga: {
            ga_id: 'UA-323874-13'
        },

        captcha: {
            site_key: '6Le8ft4cAAAAAGd8zVVBZJzZmb-6SVFuUTgOme2t',
            secret_key: '6Le8ft4cAAAAAGjDqYMkSPQApT0NwT6mPr5JfG1i'
        },

        facebook: {
            facebook_id: '464136394777416'
        },

        google: {
            maps_api_key: "AIzaSyDh8NtN39nNM6IHOLqsedyIgw3E7vkXbJQ"
        },

        seo: {
            default_title: "Hanos Kerstpakketten"
        },

        productlist: {
            pagination: {
                products_per_page: 20
            }
        }

    },

    live: {

        backend: {
            url: 'https://www.hanoskerstpakketten.nl/api',
            port: null,
            image_url: 'https://www.hanoskerstpakketten.nl/api',
        },

        ga: {
            ga_id: "UA-323874-13"
        }

    },

    acceptatie: {

        backend: {
            url: 'https://www-acc.hanoskerstpakketten.nl/api',
            port: null,
            image_url: 'https://www-acc.hanoskerstpakketten.nl/api'
        },

    },

    dev: {

        backend: {
            url: 'https://kerstpakketten-dev.kega.nl/api',
            port: null,
            image_url: 'https://kerstpakketten-dev.kega.nl/api'
        },

        google: {
            maps_api_key: "AIzaSyDHbmT7oe2JL8NzfNm7llFoNOqYmyU3SCQ"
        }

    },


}
