import classNames from 'classnames';

import { LoaderIcon } from '../../icons';

import classes from './Button.module.css';
import { Link } from 'react-router-dom';

const Button = ({ children, type, variant = "primary", loading = false, className = '', to = '', ...restProps }) => {

    const buttonClasses = classNames(classes.button, classes[variant], className);

    return (
        <>
            {
                type

                    ?

                    <button
                        className={buttonClasses}
                        type={type}
                        disabled={loading}
                        {...restProps}
                    >
                        {
                            loading
                                ?
                                <div className={classes.loading}><LoaderIcon color={'#ffffff'} /></div>
                                :
                                children
                        }
                    </button>

                    :

                    <Link
                        className={buttonClasses}
                        to={to}
                        {...restProps}
                    >
                        {
                            children
                        }
                    </Link>
            }
        </>

    );

}

export default Button;