import classes from './ProductSort.module.css';

const ProductSort = ({ sort }) => {

    const [currentSort, setSort] = sort;

    const options = [
        { key: 'relevance', value: 'Relevantie'},
        //{ key: 'popular', value: 'Populariteit'},
        { key: 'price:asc', value: 'Prijs oplopend'},
        { key: 'price:desc', value: 'Prijs aflopend'}
    ];

    const onChange = ({ target: { value } }) => {
        setSort(value);
    }

    return (
        <div className={classes.root}>
            <select 
                className={classes.select}
                name="sort"
                value={currentSort || ''}
                onChange={onChange} 
            >
                {
                    options.map((option) => {
                        return <option key={option.value} value={option.key}>{option.value}</option>;
                    })
                }
            </select>
        </div>
    );
}

export default ProductSort;