import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { addNavigation } from '../redux/navigation/actions';

import Kerstpakketten from '../lib/api/Kerstpakketten';

const useNavigation = (menu_location=null) => {
    const dispatch = useDispatch();
    const { pages } = useSelector(state => state.navigation);

    useEffect(() => {

        const fetchData = async () => {

            try {
                const data = await Kerstpakketten.getPages();
                dispatch(addNavigation(data));
            } catch (error) {
                dispatch(addNavigation([]));
                console.log('Error:', error);
            }
        };

        if (pages === null) {
            fetchData();
        }

    }, [pages, dispatch]);

    const filtered_pages = useMemo(() => {

        if (pages === null) { return null }
        if (menu_location === null) { return pages }
        
        return pages.reduce((pages, page) => {

            if (page.nav_location === menu_location && page.nav_visible) {
                pages.push(page);
            } 

            return pages;

        }, [])

    }, [pages, menu_location]);

    return filtered_pages;
}

export default useNavigation;