import IconWrapper from '../wrapper/IconWrapper';

const RefreshIcon = ({ size = 24, color = null, className = null }) => {
    return (
        <IconWrapper size={size} color={color} className={className}>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14.4175 8.75003H19.3325C19.3919 8.75005 19.45 8.76699 19.5001 8.79886C19.5502 8.83074 19.5902 8.87623 19.6154 8.93C19.6406 8.98378 19.6499 9.04361 19.6423 9.1025C19.6347 9.1614 19.6105 9.2169 19.5725 9.26253L17.115 12.2125C17.0857 12.2477 17.049 12.276 17.0075 12.2954C16.966 12.3148 16.9208 12.3249 16.875 12.3249C16.8292 12.3249 16.784 12.3148 16.7425 12.2954C16.701 12.276 16.6643 12.2477 16.635 12.2125L14.1775 9.26253C14.1395 9.2169 14.1153 9.1614 14.1077 9.1025C14.1001 9.04361 14.1094 8.98378 14.1346 8.93C14.1598 8.87623 14.1998 8.83074 14.2499 8.79886C14.3 8.76699 14.3581 8.75005 14.4175 8.75003ZM0.667486 11.25H5.58249C5.64187 11.25 5.70001 11.2331 5.75011 11.2012C5.80021 11.1693 5.84019 11.1238 5.86537 11.0701C5.89056 11.0163 5.8999 10.9564 5.8923 10.8976C5.88471 10.8387 5.86049 10.7832 5.82249 10.7375L3.36499 7.78753C3.33566 7.75236 3.29896 7.72407 3.25749 7.70465C3.21601 7.68524 3.17078 7.67517 3.12499 7.67517C3.07919 7.67517 3.03396 7.68524 2.99249 7.70465C2.95101 7.72407 2.91431 7.75236 2.88499 7.78753L0.427486 10.7375C0.389483 10.7832 0.365266 10.8387 0.35767 10.8976C0.350074 10.9564 0.359415 11.0163 0.384597 11.0701C0.40978 11.1238 0.449762 11.1693 0.499862 11.2012C0.549962 11.2331 0.608106 11.25 0.667486 11.25Z" fill="white" />
                <path fillRule="evenodd" clipRule="evenodd" d="M10 3.75005C8.06001 3.75005 6.32501 4.6338 5.17876 6.02255C5.12758 6.08889 5.06357 6.14426 4.99056 6.18537C4.91755 6.22648 4.83701 6.25249 4.75375 6.26185C4.67048 6.27121 4.58618 6.26374 4.50586 6.23987C4.42554 6.216 4.35084 6.17622 4.28621 6.1229C4.22157 6.06958 4.16831 6.00381 4.1296 5.92949C4.0909 5.85518 4.06753 5.77384 4.06088 5.69031C4.05424 5.60678 4.06446 5.52277 4.09094 5.44327C4.11742 5.36377 4.15961 5.29041 4.21501 5.22755C5.13025 4.11946 6.3445 3.29756 7.71334 2.85962C9.08218 2.42167 10.548 2.38611 11.9365 2.75716C13.3249 3.12821 14.5776 3.89025 15.5455 4.95266C16.5134 6.01506 17.1558 7.33311 17.3963 8.75005H16.125C15.8364 7.33856 15.0692 6.07004 13.9532 5.15898C12.8371 4.24792 11.4407 3.75022 10 3.75005ZM3.87501 11.25C4.11165 12.4058 4.67045 13.4711 5.48686 14.3227C6.30326 15.1744 7.34396 15.7777 8.48871 16.0629C9.63347 16.3481 10.8356 16.3037 11.9561 15.9347C13.0767 15.5657 14.07 14.8872 14.8213 13.9775C14.8724 13.9112 14.9364 13.8558 15.0095 13.8147C15.0825 13.7736 15.163 13.7476 15.2463 13.7382C15.3295 13.7289 15.4138 13.7364 15.4942 13.7602C15.5745 13.7841 15.6492 13.8239 15.7138 13.8772C15.7785 13.9305 15.8317 13.9963 15.8704 14.0706C15.9091 14.1449 15.9325 14.2263 15.9391 14.3098C15.9458 14.3933 15.9356 14.4773 15.9091 14.5568C15.8826 14.6363 15.8404 14.7097 15.785 14.7725C14.8698 15.8806 13.6555 16.7025 12.2867 17.1405C10.9178 17.5784 9.45201 17.614 8.06354 17.2429C6.67507 16.8719 5.42239 16.1098 4.4545 15.0474C3.4866 13.985 2.84422 12.667 2.60376 11.25H3.87501Z" fill="white" />
            </svg>
        </IconWrapper>
    );
}

export default RefreshIcon;