import { Builder } from "@builder.io/react/lite";

export const Dummy = () => (
    <>
    </>
);

Builder.registerComponent(Dummy, {
    name: "Dummy",
    hideFromInsertMenu: true
});