import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import { Col, Row, Container } from '../../../components/grid';
import { addCart } from '../../../redux/application/actions';

import classes from './Success.module.css';

const Success = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { cart } = useSelector(state => state.app);


    useEffect(() => {
        if (Object.keys(cart).length === 0) {
            navigate('/');
        }
        return () => {
            dispatch(addCart({}));
        }
    }, [])

    return (
        <>
            <Helmet>
                <title>Succesvol aangevraagd</title>
                <meta name="description" content="Uw offerte wordt verzonden naar uw dichtstbijzijnde HANOS kerstshowroom. Onze HANOS Kerstspecialisten nemen zo snel mogelijk contact met u op." />
            </Helmet>

            <Container xl margins g-xs={2} className={classes.checkout_container}>
                <Row>
                    <Col md={8}>
                        <div className={classes.info}>
                            <h1>Bedankt voor uw order!</h1>
                        </div>
                        <h2 className={classes.title}>Wat kunt u van ons verwachten?</h2>
                        <Row>
                            <Col>
                                <p>Uw order wordt verzonden naar uw dichtstbijzijnde HANOS kerstshowroom. Onze HANOS Kerstspecialisten nemen zo snel mogelijk contact met u op.<br />
                                    <br />
                                    Uitpakken is bij ons een feest!</p>
                            </Col>
                        </Row>
                    </Col>
                    <Col md={4}>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default Success;