import { NavLink } from 'react-router-dom';
import { Container } from '../../grid';

import { ChevronIcon } from '../../icons';

import useNavigation from '../../../hooks/useNavigation';
import useCategories from '../../../hooks/useCategories';

import classes from './BottomMenu.module.css';

const BottomMenu = () => {
    const pages = useNavigation('bottom');
    const { categories } = useCategories();

    return (
        <Container xl margins g-xs={2}>
            <nav className={classes.menu}>
                {
                    
                    categories !== null && categories.map(({ key, name, parent_category, categories=[] }) => {
                        if (!parent_category) {
 
                            return (
                                <div key={key} className={classes.item}>
                                    <span>{ name }</span>
                                    <span className={classes.chevron} >
                                        <ChevronIcon size={16} direction='bottom' />
                                    </span>
                
                                    <nav className={classes.submenu}>
                                        <NavLink key={key} to={'/' + key}>{ name }</NavLink>
                                        {
                                            categories
                                            &&
                                            categories.map((category) => {
                                                return category.visible ? <NavLink key={category.key} to={'/' + category.key}>{category.name}</NavLink> : null;
                                            })
                                        }
                                    </nav>
                
                                </div>
                            )
                        }
   
                    })

                }

                {
                    pages.map(({ nav_name, url_key, type }) => {

                        if (type === 'external') {
                            return <a key={url_key} href={url_key} target="_blank" rel="noreferrer">{nav_name}</a>
                        } else {
                            return <NavLink key={url_key} to={'/' + url_key}>{nav_name}</NavLink>
                        }

                    })
                }

            </nav>
        </Container>
    );
}

export default BottomMenu;