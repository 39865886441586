import marked from 'marked';

import classes from './PackageInfo.module.css';

const PackageInfo = ({ product }) => {
    
    if (!product.contents) { return null; }

    return (
        <div>
            <h2 className={classes.title}>Wat zit erin?</h2>
            <div className={classes.content}>
                {
                    product.contents
                    &&
                    <div
                        dangerouslySetInnerHTML={{
                            __html: marked(product.contents, { headerIds: false })
                        }}
                    />
                }
            </div>
        </div>
    );
}

export default PackageInfo;