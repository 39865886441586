import classes from './Usp.module.css';
import CheckmarkIcon from '../../../icons/icons/CheckmarkIcon';

const Usp = () => {
    return (
        <div className={classes.root}>
            <h2 className={classes.title}>Dé kerstpakkettenspecialist</h2>
            <ul className={classes.list}>
                <li className={classes.usp_list}><CheckmarkIcon size={14} color="#37592A" className={classes.icon} /> Al 45 jaar ervaring met kerstpakketten</li>
                <li className={classes.usp_list}><CheckmarkIcon size={14} color="#37592A" className={classes.icon} /> Keuze uit meer dan 2000 kerstgeschenken</li>
                <li className={classes.usp_list}><CheckmarkIcon size={14} color="#37592A" className={classes.icon} /> Altijd een kerstpakket dat past bij uw bedrijf</li>
            </ul>
        </div>
    );
}

export default Usp;