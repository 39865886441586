import { Builder } from '@builder.io/react/lite';

export const Heading = props => {
    const { Type, Title } = props;


    return (
        <Type>
            {Title}
        </Type>
    );
}


Builder.registerComponent(Heading, {
    name: "Heading element",
    inputs: [
        {
            type: 'string',
            name: 'Type',
            required: true,
            enum: [
                {
                    label: 'H1',
                    value: 'h1',
                },
                {
                    label: 'H2',
                    value: 'h2',
                },
                {
                    label: 'H3',
                    value: 'h3',
                },
                {
                    label: 'H4',
                    value: 'h4',
                },
                {
                    label: 'H5',
                    value: 'h5',
                },
                {
                    label: 'H6',
                    value: 'h6',
                }
            ],
            defaultValue: 'h1',
        }, {
            name: 'Title',
            type: 'string',
            defaultValue: 'Your Title Here',
        }
    ],
});
