
import { NavLink } from 'react-router-dom';

import { ChevronIcon } from '../../icons';

import classes from './CategoryLinks.module.css';

const CategoryLinks = ({ categories=[] }) => {

    if (categories.length === 0 ) { return null; }

    return (
        <div className={classes.root} >
            <h3 >Categorieën</h3>
            {
                [...categories].sort((a, b) => {
                    return a.sequence - b.sequence;
                }).map(({ key, name, visible }) => {
                    return visible ? <NavLink key={key} to={"/"+ key}><ChevronIcon size={12} direction="right"/>{ name }</NavLink> : null;
                })
            }
        </div>
    );
}

export default CategoryLinks;