import { useMemo } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import YouTube from 'react-youtube';

import useProduct from '../../hooks/useProduct';

import Loading from '../../components/loading/Loading';
//import NotFound from '../../components/not_found/NotFound';
import { Col, Row, Container } from '../../components/grid';
import Breadcrumb from '../../components/breadcrumb/Breadcrumb';
import Gallery from '../../components/product/detail/gallery/Gallery';
import Info from '../../components/product/detail/info/Info';
import Usp from '../../components/product/detail/usp/Usp';
import ComposeCta from '../../components/product/detail/compose_cta/ComposeCta';
import PackageInfo from '../../components/product/detail/package_info/PackageInfo';

import Config from '../../lib/config/Config';

import classes from './ProductDetail.module.css';

const ProductDetail = () => {
    const { pathname, state } = useLocation();
    const { product_key } = useParams();
    const { product, loading } = useProduct(product_key, (state && state.product ? state.product : null));

    const category_key = pathname.substring(1).split('/')[0];
    const category = useMemo(() => {
        if (product === null) { return null; }

        return product.categories.find(({ key }) => {
            return key === category_key
        })
    }, [category_key, product]);

    if (loading && product === null) { return <Loading page />; }
    if (product === null) { return null; } // <NotFound />

    const { YouTubeId=null } = product;

    return (
        <>
            <Helmet>
                <title>{product.name || ''}</title>
                <meta name="description" content={product.description || ''} />
            </Helmet>

            <Container xl margins g-xs={2}>
                <Row>
                    <Col xs={12}>
                        <Breadcrumb
                            prefix={[{ label: 'Home', path: '/' }]}
                            paths={[{ label: category.name, path: '/' + category_key }]}
                            sufix={[{ label: product.name }]}
                        />
                    </Col>
                </Row>
            </Container>

            <Container xl margins g-xs={2}>
                <Row>
                    <Col md={8}>
                        {
                            YouTubeId
                            ?
                            <YouTube 
                                videoId={YouTubeId} 
                                className={classes.video}
                                opts={{ width: '100%', height: '100%', playerVars: { autoplay: 0 } }}
                            />
                            :
                            <Gallery product={product} />
                        }
                    </Col>
                    <Col md={4}>
                        <Info product={product} />
                        <Usp />
                    </Col>
                </Row>
                <Row className={classes.product_information}>
                    <Col md={8}>
                        <PackageInfo product={product} />
                        { /* <Attributes product={product} /> */}
                    </Col>
                    <Col md={4}>
                        <ComposeCta />
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default ProductDetail;