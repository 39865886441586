import { useState, useMemo, useRef } from 'react';

import useQueryString from './useQueryString';


const deepClone = (inObject) => {
    let outObject, value, key;

    if (typeof inObject !== 'object' || inObject === null) {
        return inObject;
    }

    outObject = Array.isArray(inObject) ? [] : {};

    for (key in inObject) {
        value = inObject[key];

        outObject[key] = deepClone(value);
    }

    return outObject;
};


const getFilterParams = ({ filter={} }) => {

    Object.entries(filter).forEach(([key, value]) => {
        if (!Array.isArray(value)) {
            filter[key] = [value];
        }
    });

    return filter;
};


const productHasFilter = (product, filter) => {

    const [key, options] = filter;
    let options_count = 0;

    options.forEach((option) => {
        const hasOption = product.filters.find(({ value }) => value === option);
        if (hasOption) {
            options_count++;
        }
    });

    // Match one option (or)
    return options_count;
    
    // Match all options (and)
    //return options_count === options.length;
};


const useFilters = (category) => {

    const params = useQueryString();
    const active_filters = getFilterParams(params);
    const prev_filters = useRef(null);
    const prev_category = useRef(null);

    const sort = useState('relevance');
    const [currentSort] = sort;


    const products = useMemo(() => {
        return category ? category.products : null;
    }, [category]);

    const filters = useMemo(() => {
        return category ? category.filters : null;
    }, [category]);

    const filtered_products = useMemo(() => {
        
        let filtered_products = null;
        const filters_array = Object.entries(active_filters);

        if (products) {
            filtered_products = products.filter((product) => {

                let filters_count = 0;

                filters_array.forEach((filter) => {
                    
                    const hasFilter = productHasFilter(product, filter);

                    if (hasFilter) {
                        filters_count++;
                    }
                });

                return filters_count === filters_array.length;
            });
        }

        return filtered_products || products;
    }, [products, active_filters]);


    const sorted_products = useMemo(() => {
        let filtered_products_copy = null;

        if (filtered_products) {
            filtered_products_copy = [...filtered_products];
            filtered_products_copy.sort((a, b) => {
            
                if (currentSort === 'price:asc') {
                    return Number(a.price) - Number(b.price);
                } else if (currentSort === 'price:desc') {
                    return Number(b.price) - Number(a.price);
                } else if (currentSort === 'popular') {
                    return b.popular - a.popular;
                } else if (currentSort === 'relevance') {
                    return 0; // Strapi order
                }
    
                return 0;
            });
        }

        return filtered_products_copy;
    }, [currentSort, filtered_products]);


    const updated_filters = useMemo(() => {
        
        let updated_filters = null;

        // Reset previous filters when switching category
        if (category && category.key !== prev_category.current) {
            
            prev_category.current = category.key;
            prev_filters.current = null;
        }


        if (filtered_products) {

            const quantity_map = filtered_products.reduce((map, product) => {
                product.filters.forEach(({ id }) => {
                    if (map[id]) {
                        map[id]++;
                    } else {
                        map[id] = 1;
                    }
                });
                return map;
            }, {});
            
            updated_filters = deepClone(prev_filters.current || filters);

            if (updated_filters) {
                updated_filters.forEach((filter) => {
                    
                    // Only update filters that are not active
                    // Active filters keep prev_filters state
                    if (active_filters[filter.key]) { return; }
                                    
                    filter.options.forEach((option) => {
                        
                        option.quantity = quantity_map[option.id] || 0;

                        //console.log(filter.key + ' -> ' + option.name + ' -> ' + option.quantity);
                    });
                });
            }

            prev_filters.current = updated_filters;
        }
        
        return updated_filters || filters;
    }, [filters, active_filters, filtered_products, category]);


    return {
        products: (sorted_products ? [...sorted_products] : null), //filtered_products,
        filters: updated_filters,
        active_filters: active_filters,
        sort: sort
    };
};

export default useFilters;