import WebService from '../webservice/WebService';
import qs from 'qs';

import Config from '../config/Config';

let instance = null;

class Kerstpakketten {

    constructor() {
     
        if(!instance){

            const { url, port=null } = Config.get('backend');

            this.webservice = new WebService({
                url: url,
                port: port
            });

            instance = this;
        }
    }

    static getPages = () => { return instance.getPages(); }
    getPages() {
        return this.webservice.get('/pages/flat');
    }

    static getLocations = () => { return instance.getLocations(); }
    getLocations() {
        return this.webservice.get('/locations');
    }

    static getLocationsNear = (data) => { return instance.getLocationsNear(data); }
    getLocationsNear({ lon, lat, maxDistance }) {
        return this.webservice.get('/locations/near', {
            lon: lon,
            lat: lat,
            maxDistance: maxDistance
        });
    }
    
    static getLocation = (key) => { return instance.getLocation(key); }
    getLocation(key) {
        const query = qs.stringify({
            'filters[key]': key,
            populate: [
                'image',
                'regularHours',
                'regularHours.monday',
                'regularHours.tuesday',
                'regularHours.wednesday',
                'regularHours.thursday',
                'regularHours.friday',
                'regularHours.saturday',
                'regularHours.sunday',
                'specialHours'
            ]
        },{
              encodeValuesOnly: true, // prettify URL
        });

        return this.webservice.get('/locations?'+query);
    }

    static getFooter = () => { return instance.getFooter(); }
    getFooter() {
        return this.webservice.get('/footer?populate[0]=link_block_1&populate[1]=link_block_2&populate[2]=link_block_3');
    }

    static sendAppoinment = (params) => { return instance.sendAppoinment(params); }
    sendAppoinment(params) {
        return this.webservice.post('/mail/appointment', params);
    }

    static sendContact = (params) => { return instance.sendContact(params); }
    sendContact(params) {
        return this.webservice.post('/mail/contact', params);
    }

    static sendQuotation = (params) => { return instance.sendQuotation(params); }
    sendQuotation(params) {
        return this.webservice.post('/checkout/quotation', params);
    }

    static getCategories = () => { return instance.getCategories(); }
    getCategories() {
        return this.webservice.get('/categories');
    }

    static getProduct = (key) => { return instance.getProduct(key); }
    getProduct(key) {

        
        const query = qs.stringify({
            filters: {
                key: { $eq: key }
            },
            populate: [
                'image',
                'labels',
                'filters',
                'categories',
                'video'
            ]
        }, {
            encodeValuesOnly: true
        });

        return this.webservice.get('/products?'+query);
    }

    static getConfig = () => { return instance.getConfig(); }
    getConfig() {

        const query = qs.stringify({
            populate: [
                'usps',
                'usps.icon'
            ]
        },{
              encodeValuesOnly: true,
        });

        return this.webservice.get('/config?'+query);
    }


}

export default new Kerstpakketten();