import IconWrapper from '../wrapper/IconWrapper';

const MapIcon = ({ size = 24, color = null, direction='top', className = null, onClick=null }) => {
    return (
        <IconWrapper size={size} color={color} direction={direction} className={className} onClick={onClick}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z"/>
            </svg>
        </IconWrapper>
    );
}

export default MapIcon;