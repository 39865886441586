import { useEffect, useMemo } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { addCategories } from '../redux/categories/actions';

import Kerstpakketten from '../lib/api/Kerstpakketten';


const sortCategories = (categories) => {
    categories.sort((a, b) => {
        return a.sequence - b.sequence;
    });
    return categories;
};

const useCategories = () => {
    
    const dispatch = useDispatch();
    const { data, error, loading } = useSelector(state => state.categories);
    
    const categories = useMemo(() => {
        return data || null;
    }, [data]);

    useEffect(() => {

        let cancelRequest = false;

        const fetchData = async () => {

            dispatch(addCategories.request());

            try {
                const categories = await Kerstpakketten.getCategories();
                const categories_sorted = categories ? sortCategories(categories) : null;
                if (!cancelRequest) { dispatch(addCategories.success(categories_sorted)); }
            } catch (error) {
                if (!cancelRequest) { dispatch(addCategories.error(error)); }
            }
        };

        if (categories === null) {
            fetchData();
        }

        return () => {
            cancelRequest = true;
        };
    }, [categories, dispatch]);


    return {
        categories,
        error,
        loading
    };
}

export default useCategories;