import configData from '../../config/config';


class Config {

    constructor() {
        this.config = this.mergeConfig(configData);
    }

    mergeConfig = (configData) => {

        const environment = process.env.REACT_APP_ENVIRONMENT;

   
        if (Object.prototype.hasOwnProperty.call(process.env, 'REACT_APP_ENVIRONMENT') && configData[environment]) {
            return this.mergeDeep(configData.default, configData[environment]);
        }

        return configData.default;
    }

    set = (key, value) => {
        this.config[key] = value;
    }

    get = (key) => {
        return this.config[key];
    }

    getAll = () => {
        return this.config;
    }

    mergeDeep = (...objects) => {
        const isObject = obj => obj && typeof obj === 'object';

        return objects.reduce((prev, obj) => {
            Object.keys(obj).forEach((key) => {
                const pVal = prev[key];
                const oVal = obj[key];

                if (Array.isArray(pVal) && Array.isArray(oVal)) {
                    prev[key] = pVal.concat(...oVal);
                } else if (isObject(pVal) && isObject(oVal)) {
                    prev[key] = this.mergeDeep(pVal, oVal);
                } else {
                    prev[key] = oVal;
                }
            });

            return prev;
        }, {});
    }

}

const config = new Config();

export default config;

export const useConfig = (key) => {
    return config.get(key);
}