import { useReducer } from 'react';

const initialState = {
    success: false,
    error: null,
    sending: false,
    data: null
};

const reducer = (state, action) => {
    switch (action.type) {
        case 'request':
            return  {
                ...state,
                error: null,
                sending: true,
                success: false
            };
        case 'success':
            return {
                ...state,
                data: action.payload,
                error: null,
                sending: false,
                success: true
            };
        case 'error':
            return {
                ...state,
                error: action.error,
                sending: false,
                success: false
            };
      default:
        throw new Error();
    }
}

const useRequestState = (initial=initialState) => {
    const [state, dispatch] = useReducer(reducer, initial);

    const request = () => { dispatch({ type: 'request'}); }
    const success = (data=null) => { dispatch({ type: 'success', payload: data }); }
    const error = (error=null) => { dispatch({ type: 'error', error: error }); }

    return {
        success: state.success,
        data: state.data,
        error: state.error,
        sending: state.sending,
        setRequest: request,
        setSuccess: success,
        setError: error
    };
}

export default useRequestState;