import classNames from 'classnames';

import classes from './Input.module.css';

const Input = ({ name = "", type = 'text', value = '', label = '', placeholder = ' ', error = false, required = false, onChange = () => { }, ...rest_props }) => {

    const inputClasses = classNames(classes.input, label ? '' : classes.no_label, (error && classes.input_error));

    return (
        <div className={classes.field}>
            {label !== '' && <label htmlFor={name} className={classes.label}>{label} { (required && <span className={classes.asterisk}>*</span>)}</label>}

            <input
                className={inputClasses}
                type={type}
                name={name}
                value={value}
                placeholder={placeholder}
                onChange={onChange}
                required={required}
                {...rest_props}
            />

        </div>
    );

}

export default Input;