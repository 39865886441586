import classNames from 'classnames';
import classes from './Filter.module.css';


const Filter = ({ id, name, options, active, onChange }) => {

    const onClick = (value) => {

        if (onChange) {
            onChange(id, value);
        }
    };

    return (
        <div className={classes.root}>
            <h3 className={classes.filter_name}>{name}</h3>
            {
                options.map((option) => {

                    const className = classNames(classes.option, {
                        [classes.active]: active ? active.includes(option.value) : false
                    });

                    return option.quantity ? <button type="button" className={className} key={String(option.value)} onClick={onClick.bind(this, option.value)}>{option.name} <span>({option.quantity})</span></button> : null;
                })
            }
        </div>
    );
}

export default Filter;