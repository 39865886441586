import React, { Suspense } from 'react';
import { Col, Row, Container } from '../../../components/grid';

import { useNavigate } from "react-router-dom";

//import Map from '../../../components/googlemaps/Map';
//import Marker from '../../../components/googlemaps/Marker';

import Breadcrumb from '../../../components/breadcrumb/Breadcrumb';

import Config from '../../../lib/config/Config';

import useLocations from '../../../hooks/useLocations';

import List from '../list/list/List';

import classes from './LocationList.module.css';

const Map = React.lazy(() => import(/* webpackChunkName: "Map" */ /* webpackPreload: true */ '../../../components/googlemaps/Map'));
const Marker = React.lazy(() => import(/* webpackChunkName: "Marker" */ /* webpackPreload: true */ '../../../components/googlemaps/Marker'));

const LocationList = () => {
    const { locations, loading, search, searchLatLon, center } = useLocations();
    const navigate = useNavigate();

    let mapZoom = (center !== null ? 8 : 7);
    let mapCenter = (center !== null ? center : { lat: 52.132633, lng: 5.291266 });

    return (
        <Container xl margins g-xs={2}>

            <Row>
                <Col xs={12}>
                    <Breadcrumb
                        prefix={[{ label: 'Home', path: '/' }]}
                        sufix={[{ label: 'Showrooms' }]}
                    />
                </Col>

                <Col xs={12}>
                    <h1>HANOS KERSTPAKKETTEN SHOWROOMS</h1>
                </Col>
            </Row>
                
            <Row className={classes.locations}>
            
                <List 
                    locations={locations} 
                    onSearch={(value) => { search(value) }}
                    onSearchLatLon={searchLatLon}
                    loading={loading}
                />
            
                <Col md={8} order-xs={1}>
                    <Suspense fallback={<div>Loading...</div>}>
                        <Map 
                            zoom={mapZoom}
                            center={mapCenter} 
                            className={classes.map} 
                            ApiKey={Config.get('google').maps_api_key}
                        >
                            {
                                locations.filter((location) => {
                                    return location !== undefined && location?.coordinates !== null;
                                }).map((location) => {
                                    const { key, name, coordinates, street, number, postalcode, city } = location;

                                    return (
                                        <Marker 
                                            key={key}
                                            title={name}
                                            position={{
                                                lng: coordinates.longitude,
                                                lat: coordinates.latitude
                                            }}
                                            street={street}
                                            number={number}
                                            postalcode={postalcode}
                                            city={city}
                                            onButtonClick={() => {
                                                navigate('/showrooms/'+key, { location: location })
                                            }}
                                        />
                                    );
                                })
                            }
                        </Map>
                    </Suspense>
                </Col>

            </Row>

        </Container>
    );

}

export default LocationList;