import classes from './ProductLabels.module.css';

const ProductLabels = ({ product: { inStock, labels=[] } }) => {
    return (
        <div className={classes.labels}>

            {
                !inStock
                ? 
                <div className={classes.sold_out + ' ' + classes.label}>ga naar de vestiging</div>
                :
                <>
                {
                    labels.map(({ label, color, textColor='#ffffff' }, index) => {
                        return <div key={String('label'+index)} className={classes.label} style={{backgroundColor: color, color: textColor}}>{ label }</div>
                    })
                }
                </>
            }
        </div>
    );
}

export default ProductLabels;