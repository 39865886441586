import { useEffect } from 'react';

import Router from './modules/router/Router';

import useConfig from './hooks/useConfig';

const App = () => {

    const { fetchConfig } = useConfig();


    useEffect(() => {
        fetchConfig();
    }, [fetchConfig]);

    return (
        <>
            <Router />
        </>
    );
}

export default App;