import { parse, isToday } from 'date-fns';
import { nl } from 'date-fns/locale';
import format from 'date-fns/format'

import classes from './TimesList.module.css';

const Timeslist = ({ hours=[], specialHours=[] }) => {

    if (hours === null) { return null; }

    delete hours.id;

    return (
        <ul className={classes.list}>
            {
                hours 
                &&
                Object.keys(hours).map((key) => {
     
                    const { openTime, closeTime, open } = hours[key];

                    const date = parse(key, 'iiii', new Date());

                    let day = format(date, 'iiii', { locale: nl });
                    day = day.charAt(0).toUpperCase() + day.slice(1);

                    let isOpen = open;

                    let timeOpen = '';
                    let timeClosed = '';

                    if (openTime !== null && closeTime !== null) {
                
                        const [ohh, omm] = openTime.split(':');
                        const [chh, cmm] = closeTime.split(':');
                        
                        timeOpen = ohh + ':' + omm;
                        timeClosed = chh + ':' + cmm;
                    }

                    const formated_date = format(date, 'yyyy-MM-dd');

                    if (specialHours[0] === null) {
                        return false;
                    }

                    const special = specialHours.find(({ date }) => {
                        return (date === formated_date);
                    });


                    if (special) {
                        isOpen = special.open;
                        if (isOpen) {
                            const [sohh, somm] = special.openTime.split(':');
                            const [schh, scmm] = special.closeTime.split(':');

                            timeOpen = sohh + ':' + somm;
                            timeClosed = schh + ':' + scmm;
                        }
                    }

                    return (
                        <li key={key} className={(isToday(date) ? classes.bold : null)}>
                            {
                                isOpen
                                    ?
                                    <>
                                        <span className={classes.day}>{day}</span>
                                        {timeOpen} <span className={classes.devider}>-</span> {timeClosed}
                                    </>
                                    :
                                    <><span className={classes.day}>{day}</span>Gesloten </>
                            }
                        </li>
                    );
                })
            }
        </ul>
    );

}

export default Timeslist;