import { useImperativeHandle, forwardRef, useState } from 'react';

import { NavLink } from 'react-router-dom';

import classNames from 'classnames';

import { CrossIcon, ChevronIcon } from '../../icons';

import Portal from '../../portal/Portal';

import useNavigation from '../../../hooks/useNavigation';
import useCategories from '../../../hooks/useCategories';

import classes from './MobileMenu.module.css';

const MobileMenu = (props, ref) => {
    const [open, setOpen] = useState(false);
    const [closing, setClosing] = useState(false);
    const [submenu, setOpenSubMenu] = useState(null);

    const top = useNavigation('bottom');
    const bottom = useNavigation('top');
    const { categories } = useCategories();


    useImperativeHandle(ref, () => ({
        openMenu: () => { setOpen(true); }
    }));

    const onAnimationEnd = () => {
        if (closing) {
            setOpen(false);
            setClosing(false);
            setOpenSubMenu(null);
        }
    }

    return (
        <Portal>
            {
                open
                &&
                <div
                    className={classNames(classes.container, (closing && classes.closing))}
                    onClick={() => { setClosing(true); }}
                    onAnimationEnd={onAnimationEnd}
                >
                    <aside className={classes.aside} onClick={(event) => event.stopPropagation()}>
                        <div className={classes.header}>
                            <div className={classes.close} onClick={() => { setClosing(true); }}>
                                <CrossIcon />
                            </div>
                            <span className={classes.heading}>MENU</span>
                        </div>

                        <div className={classes.overflow}>

                            <nav className={classes.menu}>

                                <div className={classes.item} onClick={() => {
                                    setOpenSubMenu((submenu === 0 ? null : 0));
                                }}>
                                    <span className={classes.label_container}>
                                        <span>Alle kerstpakketten</span>
                                        <span className={classNames(classes.chevron, (submenu === 0 && classes.chevron_open))} >
                                            <ChevronIcon size={16} direction='bottom' />
                                        </span>
                                    </span>

                                    <nav className={classNames(classes.submenu, (submenu === 0 && classes.submenu_open))}>
                                        {
                                            categories && categories.map((category) => {
                                                return category.visible ? <NavLink key={category.key} to={'/' + category.key} onClick={() => { setClosing(true); }}>{category.name}</NavLink> : null;
                                            })
                                        }
                                    </nav>
                                </div>

                                {
                                    top.map(({ nav_name, url_key, type }) => {
                                        if (type === 'external') {
                                            return <a key={url_key} href={url_key}>{nav_name}</a>
                                        } else {
                                            return <NavLink key={url_key} to={'/' + url_key}>{nav_name}</NavLink>
                                        }
                                    })
                                }

                            </nav>

                            <nav className={classes.bottommenu}>
                                {
                                    bottom.map(({ nav_name, url_key, type }) => {
                                        if (type === 'external') {
                                            return <a key={url_key} href={url_key}>{nav_name}</a>
                                        } else {
                                            return <NavLink key={url_key} to={'/' + url_key}>{nav_name}</NavLink>
                                        }
                                    })
                                }
                            </nav>

                        </div>
                    </aside>
                </div>
            }

        </Portal>
    );
}

export default forwardRef(MobileMenu);