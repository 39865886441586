import classNames from 'classnames';

import Config from '../../lib/config/Config';

import classes from './Thumbnail.module.css';

const image_url = Config.get('backend').url;

const Thumbnail = ({ id, images=[], className=null, alt="", ...props }) => {

    const pictureClassName = classNames(classes.picture, className);

    const main = (images.length > 0 ? images[images.length-1] : null);

    return (
        <picture className={pictureClassName}>
            {
                images.map(({ key, media, width, height }) => {
                    //console.log(image_url+'/strapi-plugin-thumbnail/'+key+'/'+id);
                    return <source key={key} media={media} srcSet={image_url+'/strapi-plugin-thumbnail/'+key+'/'+id} width={width} height={height} />
                })
            }
            {
                main
                &&
                <img loading="lazy" className={classes.img} src={image_url+"/strapi-plugin-thumbnail/"+main.key+"/"+id} alt={alt} />
            }
        </picture>
    );
}

export default Thumbnail;