import { useState, useEffect } from 'react';

import classes from './CheckoutCart.module.css';

const CheckoutCart = ({ cart }) => {
    const [subTotal, setSubTotal] = useState(0);

    useEffect(() => {
        let subTotal = 0;
        Object.values(cart).map(item =>
            subTotal += Number(item.total_price.replace(',', '.'))
        );
        
        setSubTotal(subTotal);
    }, [cart]);

    return (
        <div className={classes.cart}>
            <div className={classes.top_container}>
                <div className={classes.cart_label}>
                    Winkelwagen
                </div>
                <div>
                    Aantal
                </div>
                <div>
                    Prijs
                </div>
            </div>
            {
                Object.values(cart).map(item =>
                    <div key={item.product.id} className={classes.product_container}>
                        <div className={classes.product_info}>
                            <div className={classes.product_name}>
                                {item.product.name}
                            </div>
                            <div className={classes.product_sku}>
                                {item.product.sku}
                            </div>
                        </div>
                        <div className={classes.product_qty}>
                            {item.qty}
                        </div>
                        <div className={classes.product_price}>
                            {
                                (item.product.price !== null && item.product.price !== 0)
                                &&
                                <>
                                     {Number(item.product.price).toFixed(2).replace('.', ',')}
                                </>
                            }
                        </div>
                    </div>
                )
            }
            
            {
                subTotal !== 0
                &&
                <div className={classes.subtotal}>
                    <label>Totaal</label>
                    <label>{Number(subTotal).toFixed(2).replace('.', ',')}</label>
                </div>
            }
       
        </div>
    );
}

export default CheckoutCart;