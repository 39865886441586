import { useRef } from 'react';

import classNames from 'classnames';

import classes from './Slider.module.css';
import { ChevronIcon } from '../icons';

const Slider = ({ items, renderItem, itemCount, itemsToShow, controls = true }) => {

    const slider = useRef(null);

    const slideClassName = classNames(classes.slide, classes['count-' + itemCount], classes['show-' + itemsToShow]);


    const onClick = (direction) => {

        const slide_width = slider.current.children[0].clientWidth;
        const offset_x = direction === 'next' ? slide_width : slide_width * -1;

        slider.current.scrollBy(offset_x, 0);
    }

    /*const onScroll = () => {

        console.log('scrollLeft',slider.current.scrollLeft);
        console.log('clientWidth',slider.current.clientWidth);
        console.log('scrollWidth',slider.current.scrollWidth);

        if (slider.current.scrollLeft <= 0) {
            console.log('Start');
        } else if (slider.current.scrollLeft >= (slider.current.scrollWidth - slider.current.clientWidth)) {
            console.log('End');
        }
    }*/

    if (items === null) { return null; }

    return (
        <div className={classes.root}>

            {
                controls &&
                <>
                    <div className={classes.prev}>
                        <button type="button" onClick={onClick.bind(this, 'prev')}><ChevronIcon size={16} direction="left" /></button>
                    </div>
                    <div className={classes.next}>
                        <button type="button" onClick={onClick.bind(this, 'next')}><ChevronIcon size={16} direction="right" /></button>
                    </div>
                </>
            }

            <div ref={slider} className={classes.slides} /*onScroll={onScroll}*/ >
                {
                    items.slice(0, itemCount).map((item, index) => {
                        return (
                            <div key={index} className={slideClassName}>
                                {renderItem(item, index)}
                            </div>
                        );
                    })
                }
            </div>

        </div >
    );
}

export default Slider;