import { useState, useEffect } from 'react';

const useMediaQuery = (query) => {

    const [match, setMatch] = useState(window.matchMedia(query).matches);

    useEffect(() => {
        const mediaQuery = window.matchMedia(query);

        setMatch(mediaQuery.matches);

        const onChange = (event) => {
            setMatch(event.matches);
        };

        if (mediaQuery.addEventListener) {
            mediaQuery.addEventListener('change', onChange);
        } else {
            mediaQuery.addListener(onChange);
        }

        return () => {
            if (mediaQuery.addEventListener) {
                mediaQuery.removeEventListener('change', onChange);
            } else {
                mediaQuery.removeListener(onChange);
            }
        };
    }, [query]);

    return {
        match
    };
}

export default useMediaQuery;