import { Routes, Route } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import LocationList from './list/LocationList';
import LocationDetails from './details/LocationDetails';

const Locations = ({ seo = {} }) => {
    return (
        <>
            <Helmet>
                <title>{seo.title || ''}</title>
                <meta name="description" content={seo.description || ''} />
            </Helmet>

            <Routes>
                <Route index element={<LocationList />} />
                <Route path={':location_key'} element={<LocationDetails />} />
            </Routes>
        </>
        
    );

}

export default Locations;