import { Routes, Route } from 'react-router-dom';

import ProductList from './ProductList';
import ProductDetail from './ProductDetail';


const Products = () => {
    return (
        <Routes>
            <Route index element={<ProductList />} />
            <Route path={':product_key'} element={<ProductDetail />} />
            {
            /*
                <Route path={path + '/:category_key'} exact component={ProductList} />
                <Route path={path + '/:category_key/:product_key'} exact component={ProductDetail} />
            */
            }
        </Routes>
    );
    
}

export default Products;